const stepTabs = document.querySelectorAll('[step-tab]');
const stepContents = document.querySelectorAll('[step-content]');
const stepButton = document.querySelector('[step-button]');
const installmentTitle = document.querySelector('[installment-title]');
const installmentModal = document.querySelector('[installment-modal]');
const modalContent = document.querySelector('[modal-content]');
const paymentType = document.querySelector('[payment-type]');
const paymentOptions = document.querySelector('[payment-options]');
const amountInput = document.querySelector('[data-amount]');
const cartNumberInput = document.querySelector('[data-cart-number]');

const cartNameInput = document.querySelector('[data-cart-fullname]');
const cartMonthInput = document.querySelector('[data-cart-month]');
const cartYearInput = document.querySelector('[data-cart-year]');
const cartCvvInput = document.querySelector('[data-cart-cvv]');


let data = {
	paymentOptions: null,
	bankInfo: null
};


let stepElements = document.querySelectorAll('[step-elem]');

let stepTabAttr = "step-tab";
let stepContentAttr = "step-content";
let active = "-active";
let actived = "-actived";


let isEmpty = () => document.querySelectorAll('.-empty');
stepElements.forEach(element => {
	element.addEventListener("input", function () {
		if (element.validity.valid) {
			element.classList.remove('-empty');
		} else {
			element.classList.add('-empty');
		}

		let validForm = isEmpty();
		if (validForm.length === 0) {

			let response = grecaptcha.getResponse();
			if (response != 0) {
				stepButton.removeAttribute('disabled');
			} else {
				stepButton.setAttribute('disabled', 'disabled');
			}
		} else {
			stepButton.setAttribute('disabled', 'disabled');
		}
	});
});

stepButton.addEventListener('click', function () {
	let buttonDisabled = stepButton.getAttribute('disabled');
	if (!buttonDisabled) {

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		let buttonValue = stepButton.getAttribute('step-button');
		let buttonNewValue = parseInt(buttonValue) + 1;

		if (buttonNewValue <= stepTabs.length) {
			stepButton.setAttribute('step-button', buttonNewValue);

			let stepTab = document.querySelector(`[${stepTabAttr}="${buttonNewValue}"]`);
			let stepTabactived = document.querySelector(`[${stepTabAttr}="${buttonValue}"]`);

			stepTabs.forEach(item => item.classList.remove(active));
			stepTab.classList.add(active);
			stepTabactived.classList.add(actived);

			let stepContent = document.querySelector(`[${stepContentAttr}="${buttonNewValue}"]`);
			stepContents.forEach(item => item.classList.remove(active));
			stepContent.classList.add(active);

			if (buttonNewValue == stepTabs.length) {
				stepButton.innerText = "Öde";
				stepButton.classList.add('-finish');
			}
		}
	}
});

stepTabs.forEach(tab => {
	tab.addEventListener('click', function () {
		let tabValue = tab.getAttribute('step-tab');
		let buttonValue = stepButton.getAttribute('step-button');

		if (tabValue < buttonValue) {
			stepTabs.forEach((item, index) => {
				let newIndex = index + 1;
				item.classList.remove(active);

				if (newIndex > tabValue) {
					item.classList.remove(actived);
				}
			});

			if (tab.classList.contains('-actived')) {
				tab.classList.remove(actived);
			}

			tab.classList.add(active);
			stepButton.setAttribute('step-button', tabValue);

			let stepContent = document.querySelector(`[${stepContentAttr}="${tabValue}"]`);
			stepContents.forEach(item => item.classList.remove(active));
			stepContent.classList.add(active);

			if (stepButton.classList.contains('-finish')) {
				stepButton.innerText = "Devam";
				stepButton.classList.remove('-finish');
			}
		}
	});
});

let getInstalmentInfos = () => {
	let cardNumber = cartNumberInput.value.replace(/[^0-9]/g, '');
	let amountValue = amountInput.value;

	let form = {
		cardNumber: cardNumber.substring(0, 6),
		amount: amountValue.replace(/[^0-9]/g, ''),
		paymentType: paymentType.value
	};

	if (!amountValue || (cardNumber.length < 6 && cardNumber.length > 0) || !paymentType.value) return;

	closePaymentOptions();
	clearPaymentOptions();

	axios.post('/cardInfo', form)
		.then((response) => {
			let haveData = !!response.data.data;

			data.paymentOptions = haveData ? response.data.data.BankPaymentInstallmentInfoList : [];
			data.bankInfo = haveData ? (response.data.data.BankCardInfo || null) : null;

			createPaymentOptions();

			if (!cardNumber) {
				createInstalmentsTable();
			}

			if (!cardNumber) {
				closePaymentOptions();
			} else {
				openPaymentOptions();
			}
		});
}

let openInstallmentTitle = () => {
	installmentTitle.classList.remove('-disabled');
}

let closeInstallmentTitle = () => {
	installmentTitle.classList.add('-disabled');
}

let openPaymentOptions = () => {
	let status = paymentType.value == '';

	if (status) return;

	paymentOptions.removeAttribute('disabled', 'disabled');
}

let closePaymentOptions = () => {
	paymentOptions.setAttribute('disabled', 'disabled');
}

let openInstallmentModal = () => {
	let status = installmentTitle.classList.contains('-disabled');

	if (status) return;

	installmentModal.classList.remove('-hidden');
}

let closeInstallmentModal = () => {
	installmentModal.classList.add('-hidden');
}

let clearPaymentOptions = () => {
	paymentOptions.length = 0

	let option = document.createElement("option");
	option.text = 'Seçiniz'
	option.value = ''
	paymentOptions.add(option);

	closePaymentOptions();
}

let createPaymentOptions = () => {
	let amountValue = amountInput.value;

	if (!amountValue) return;

	let blackList = ['GENEL'];

	let filteredList = data.paymentOptions.filter(item => !blackList.includes(item.BankInfoName));

	let status = filteredList.some(itm => !itm.PaymentInstallmentInfoList);

	if (filteredList.length == 0 || status) {
		closePaymentOptions();
		closeInstallmentTitle();

		return
	};

	if (filteredList.length > 1) return closePaymentOptions();

	paymentOptions.length = 0;

	let options = [
		{
			text: 'Seçiniz',
			value: ''
		},
	]

	filteredList.forEach(item => {
		let list = item.PaymentInstallmentInfoList.filter(itm => itm.CommissionType == 'CreditCard');

		if (paymentType.value == '0') list = list.filter(itm => itm.InstallmentNumber > 11);

		list
			.filter(itm => itm.InstallmentNumber > 1)
			.forEach(itm => {

				let obj = {}

				obj.value = `${itm.InstallmentNumber}`;

				options.push({
					text: `${itm.InstallmentNumber} Taksit - ${itm.Amount.toLocaleString()} ₺`,
					value: `${itm.InstallmentNumber}`
				})
			});
	});

	options.forEach(item => {
		let option = document.createElement("option");
		option.text = item.text;
		option.value = item.value;
		paymentOptions.add(option);
	});

	openPaymentOptions();
}

let createInstalmentsTable = () => {
	let html = '';

	let blackList = ['GENEL'];

	let list = data.paymentOptions.filter(item => !blackList.includes(item.BankInfoName));

	let status = list.some(itm => !itm.PaymentInstallmentInfoList);

	if (list.length == 0 || status) {
		closePaymentOptions();
		closeInstallmentTitle();

		return;
	};

	list.forEach(item => {

		html += `
		<div class="modal__table">
			<div class="modal__table__logo">
				<img src="/assets/image/${item.BankInfoName}.png" height="18">
			</div>
			<div class="modal__table__header">
				<div class="modal__table__header__title">
					Taksit
				</div>
				<div class="modal__table__header__title">
					Taksit Tutarı
				</div>
				<div class="modal__table__header__title">
					Toplam Tutar
				</div>
			</div>
			<div class="modal__table__content">
		`;

		let list = item.PaymentInstallmentInfoList.filter(itm => itm.CommissionType == 'CreditCard');

		list.forEach((row, index) => {

			if (index == 0) return;

			if (paymentType.value == '0' && row.InstallmentNumber != 12) return;

			html += `
				<div class="modal__table__row">
					<div class="modal__table__row__count">
						${row.InstallmentNumber}
					</div>
					<div class="modal__table__row__amount">
						${row.PerInstallmentAmount.toLocaleString()} TL
					</div>
					<div class="modal__table__row__total">
						${row.Amount.toLocaleString()} TL
					</div>
				</div>
			`;
		});

		html += `
			</div>
		</div>
		`;

	});

	modalContent.innerHTML = html;
	openPaymentOptions();

	let amountValue = amountInput.value;
	let paymentTypeValue = paymentType.value;
	if (amountValue && paymentTypeValue) openInstallmentTitle();
}

let changePaymentType = (event) => {
	let value = event.target.value;

	if (!value) return clearPaymentOptions();

	getInstalmentInfos();

	let amountValue = amountInput.value;
	let paymentTypeValue = paymentType.value;
	if (amountValue && paymentTypeValue) openInstallmentTitle();
}

let inits = () => {
	installmentTitle.addEventListener('click', openInstallmentModal)
	installmentModal.addEventListener('click', closeInstallmentModal)
	paymentType.addEventListener('change', (event) => {
		cartNumberInput.value = "";
		cartNameInput.value = "";
		cartMonthInput.value = "";
		cartYearInput.value = "";
		cartCvvInput.value = "";

		clearPaymentOptions();

		if (paymentOptions.classList.contains('-invalid')) {
			paymentOptions.classList.remove('-invalid');
			paymentOptions.parentElement.classList.remove('-parent-invalid');
		}
		changePaymentType(event);
	})
	cartNumberInput.addEventListener('input', getInstalmentInfos);
	amountInput.addEventListener('blur', (event) => {
		cartNumberInput.value = "";
		cartNameInput.value = "";
		cartMonthInput.value = "";
		cartYearInput.value = "";
		cartCvvInput.value = "";

		clearPaymentOptions();

		if (!event.target.value) return closeInstallmentTitle();
		getInstalmentInfos();
	});

}

let onloadFunctions = () => {
	inits();
}

window.onload = onloadFunctions();